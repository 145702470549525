<template>
<v-sheet class="fill-height pa-6 dark d-flex align-center justify-center">

  <div>

    <div class="d-flex justify-center">
      <v-icon :color="wsDARKLIGHT" size="80">mdi-book-cancel-outline</v-icon>
    </div>

    <h3 class="mt-8 text-center font-weight-regular">{{ $t('newsletter.unsubscribe.title') }}</h3>
    <h2 style="max-width: 600px" v-if="businessUuid" class="text-center mt-3"> {{ businessName }}</h2>

    <div v-if="entityData.email">
      <div v-if="!isUnsubscribed" class="d-flex justify-center mt-5">
        <ws-button @click="displayDialog = true" min-width="150px"  label="Unsubscribe" ></ws-button>
      </div>
    </div>

    <h3 v-else class="mt-8 text-center font-weight-regular">{{ $t('newsletter.unsubscribe.error') }}</h3>

    <ws-dialog
        v-model="displayDialog"
        :title="$t('newsletter.unsubscribe.title')"
        :save-text="$t('Unsubscribe')"
        :cancel-text="!isUnsubscribed ? $t('Cancel') : $t('Close')"
        :hide-save="isUnsubscribed"
        :loading="LOADING"
        @save="unsubscribeNewsletter"

    >
      <h4 v-if="!isUnsubscribed" class="font-weight-regular" :class="[{'text-center' : SM}]">
        {{ $t('newsletter.unsubscribe.are_you_sure') }}
      </h4>

      <h4 v-else class="font-weight-regular">
        {{ $t('newsletter.unsubscribe.completed') }}
      </h4>

    </ws-dialog>
  </div>

</v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "UnsibscribeEmailPage",
  data() {
    return {

      entityData : {},
      businessUuid : null,
      businessName : null,
      displayDialog : false,
      isUnsubscribed : false,
    }
  },
  methods : {
    ...mapActions('newsletter', [
      'UNSUBSCRIBE_EMAIL'
    ]),

    async unsubscribeNewsletter() {
      let result = await this.UNSUBSCRIBE_EMAIL(this.entityData)
      if ( !result ) {
        console.log('Помилка звʼязку')
      }
      this.isUnsubscribed = true
    },

    base64DecodeUnicode(str) {
      // Convert Base64 encoded bytes to percent-escaped characters
      var percentEncodedStr = atob(decodeURIComponent(str)).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('');

      // Decode percent-encoded string as UTF-8
      return decodeURIComponent(percentEncodedStr);
    },
    initPage() {
      try {
        let data = JSON.parse(this.base64DecodeUnicode(this.$store.state.QUERY.ud))
        this.entityData = data
        this.businessUuid = data.business_uuid ?? null
        this.email = data.email
        this.businessName = data.business_name ?? null
      } catch (e) {
        alert(e.message)
      }
    },

    toUrlSafeBase64(base64String) {
      return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

  },
  mounted() {
    setTimeout(() => {
      if ( this.$store.state.QUERY.ud) {
        this.initPage()
      }
    } , 300)

  }
}
</script>

<style scoped>

</style>